
import Tippy from '@/components/common/Tippy.vue'
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    name: 'ReferralOffer',
    components: {
        Tippy, Icon
    }
}
