export default () => {
    return {
        // app
        'app name': 'Qanoniah',
        'home page': 'Home',
        'header manage team': 'Team',
        'header logout': 'Logout',

        // Search Page
        'search page title': 'Search',
        'what you want to search today': 'What do you want to search today?',
        'search results type summary': 'Summary',
        'search results type ai summary': 'AI Summary',
        'search results type content': 'Content',
        // Search Bar
        'remove search option': 'Remove option',
        'add search option': 'Add option',
        'search type': 'Search type',
        'please select search type first': 'Please select search type first',
        'search type all': 'All results',
        'search type verbatim': 'Exact search',
        'search here': 'Search here...',
        // Search Bar
        'search bar options': 'Search options',
        // search history
        'latest search operations': 'Latest search',
        'latest viewed search files': 'Latest viewed files',
        // search filters
        'search filters': 'Search filters',
        'search filters desc': 'You can search in more than one type',
        'reset all search filters': 'Reset all',
        'reset search filter': 'Reset',
        // main page suggestions
        'show more suggestions': 'Show more',
        'حذف': 'Delete',
        'لا توجد نتائج! قم بتغيير خيارات البحث لعرض نتائج أخرى': 'No results! Change search options to view other results',
        'الميلادي': 'Gregorian',
        'الهجري': 'Hijri',
        'إلى': 'To',
        'ابتداءاً من': 'From',
        'انتهاءاً بـ': 'To',

        // Categories
        'جار تحميل التصنيف': 'Loading Category',
        'لم يتم ايجاد التصنيف': 'Category not found',
        'مقترحات التصنيف': 'Category Suggestions',

        // help center
        'mini help center label': 'Help Center',

        // highlights & notes
        'highlights and notes page': 'My Highlights & Notes',
        'order hxn by': 'Order By',
        'order hxn by asc': 'Ascending',
        'order hxn by desc': 'Descending',
        'no hxn results': 'No highlights or notes',
        'hxn new files': 'New filesََ',
        'hxn recent files': 'Recently opened files',
        'hxn recently highlighted': 'Recently highlighted',
        'hxn suggestion': 'Suggested files',
        'التالي': 'Next',
        'السابق': 'Previous',

        // My Folders
        'my folders page': 'My Folders',
        'here shows the trashed files': 'Here shows the trashed files',
        'my folders empty folder placeholder': 'You can create a new folder or add a file to',
        'no search results for this search': 'No search results for',
        'folder not found': 'Folder not found',
        'folder name': 'Folder name',
        'my folders trash bin': 'Trash',
        'back to my folders': 'Back to my folders',
        'my folders table header name': 'Name',
        'my folders table header owner': 'Owner',
        'my folders table header creation': 'Creation',
        'my folders table header trash': 'Deleted at',
        'my folders create new folder': 'New Folder',
        'my folders create new folder modal label': 'New Folder',
        'my folders search in': 'Search in',
        'my folders show details': 'Show details',
        'my folders show in its folder': 'Show in its folder',
        'my folders open in new tab': 'Open in new tab',
        'my folders copy': 'Copy',
        'my folders copy here': 'Copy here',
        'my folders copy to': 'Copy to',
        'my folders move here': 'Move here',
        'my folders move to': 'Move to',
        'my folders trash': 'Trash',
        'my folders delete': 'Delete',
        'my folders restore': 'Restore',
        'my folders move': 'Move',
        'my folders rename': 'Rename',
        'my folders delete permanently': 'Delete permanently',
        'my folders details location': 'Location',
        'my folders details original location': 'Original location',
        'my folders details last edit': 'Last edit',
        'my folders details deletion time': 'Deletion time',
        'my folders me': 'Me',
        'my folders delete file': 'Delete file',
        'my folders delete files': 'Delete files',
        'my folders delete files confirm': 'Are you sure you want to delete the file?',
        'my folders are you sure you want to delete section 1': 'Are you sure you want to delete',
        'my folders are you sure you want to delete section 2': '{1} file?|{2,*} files?',
        'my folders delete file permanently': 'Delete file permanently',
        'my folders delete files permanently': 'Delete files permanently',
        'my folders delete files permanently confirm': 'Are you sure you want to permanently delete the files?',
        'my folders are you sure you want to permanently delete section 1': 'Are you sure you want to permanently delete',
        'my folders are you sure you want to permanently delete section 2': '{1} file?|{2,*} files?',

        // File Viewer
        'file viewer loading file': 'Loading file',
        'file viewer add to': 'Add to',
        'file viewer add': 'Add',
        'file viewer add here': 'Add here',
        'file viewer copy text': 'Copy text',
        'file viewer copy': 'Copy',
        'استثناء الشروحات من البحث': 'Exclude explanations from search',
        'تخصيص البحث': 'Customize search',
        'حفظ': 'Save',
        'مشاركة': 'Share',
        'رابط المشاركة': 'Share link',
        'لقد حصل خطأ ما': 'An error occurred',
        'نسخ': 'Copy',
        'المحتوى': 'Content',
        'عنوان التحديث': 'Update title',
        'عنوان التعديل': 'Amendment title',
        'محتوى التعديل': 'Amendment content',
        'محتوى التحديث': 'Update content',
        'رابط التعديل': 'Amendment link',
        'رابط التحديث': 'Update link',
        'الرابط': 'Link',
        'ترجمة القسم': 'Translate Section',
        'تاريخ التعديل': 'Amendment date',
        'تاريخ التحديث': 'Update date',
        'يحدث بعد': 'Updates after',
        'نسخ التحديث': 'Copy update',
        'نسخ التعديل': 'Copy amendment',
        'مشاركة التعديل': 'Share amendment',
        'يسري التعديل بعد': 'Amendment effective after',
        'ميزة النسخ تتطلب تسجيل الدخول': 'The copy feature requires logging in',
        'ادخل نص البحث هنا ...': 'Enter search text here ...',
        'إغلاق البحث': 'Close search',
        'إغلاق': 'Close',
        'لا يوجد نتائج': 'No results',
        'استعراض': 'Show',
        'في مجلداتي': 'In my folders',
        'نقل': 'Move',
        'نقل هنا': 'Move Here',
        'نقل الى': 'Move to',
        'حصل خطأ': 'An error occurred',
        'ميزة اضافة الملف الى مجلداتي تتطلب تسجيل الدخول': 'The feature of adding the file to my folders requires logging in',
        'اضف الى مجلداتي': 'Add to Folder',
        'التحديدات': 'Marks',
        'مناقشاتي': 'My Discussions',
        'وضع القراءة': 'Reading mode',
        'تحديداتي وملاحظاتي': 'My marks and notes',
        'إخفاء جميع اللوائح': 'Hide Implementing Regulations',
        'إظهار جميع اللوائح': 'Show Implementing Regulations',
        'تنزيل الملف': 'Download file',
        'ميزة تحميل الملف تتطلب تسجيل الدخول': 'The file download feature requires logging in',
        'تصغير': 'Zoom out',
        'تكبير': 'Zoom in',
        'مشاركة الملف': 'Share file',
        'معلومات الملف': 'File information',
        'البحث': 'Search',
        'بحث': 'Search',
        'الملخص': 'Summary',
        'ميزة البحث دخل الملف تتطلب تسجيل الدخول': 'The search feature inside the file requires logging in',
        'رابط ذا صلة': 'Related link',
        'عنوان القسم التالي': 'Next section title',
        'محتوى القسم التالي': 'Next section content',
        'نص تم التحديد عليه مسبقا': 'Text already marked',
        'مثال لملاحظه': 'Example of a note',
        'منذ يومان': 'Two days ago',
        'عرض محادثة الفريق': 'View team discussion',
        'إنشاء محادثة جديدة حول النص المحدد': 'Create a new discussion about the marked text',
        'ملاحظة': 'Note',
        'عرض الملاحظة': 'View note',
        'إنشاء ملاحظة': 'Create note',
        'إخفاء الملاحظة': 'Hide note',
        'ميزة التحديدات والملاحظات تتطلب تسجيل الدخول': 'The marks and notes feature requires logging in',
        'ميزة نسخ النص تتطلب تسجيل الدخول': 'The copy text feature requires logging in',
        'المحتوى التالي': 'Next content',
        'اللوائح': 'Regulations',
        'إخفاء الكل': 'Hide all',
        'إظهار الكل': 'Show all',
        'إظهار': 'Show',
        'إخفاء': 'Hide',
        'الشروحات': 'Explanations',
        'شرح': 'Explanation',
        'اسم الشرح': 'Explanation name',
        'عنوان الشرح': 'Explanation title',
        'محتوى الشرح': 'Explanation content',
        'اسم اللائحة': 'Regulation name',
        'عنوان اللائحة': 'Regulation title',
        'محتوى اللائحة': 'Regulation content',
        'التحديثات القادمة': 'Upcoming Updates',
        'التعديلات السابقة': 'Previous Amendments',
        'نوع الترجمة': 'Translation Type',
        'اختيار نوع الترجمة': 'Section Translation Type',
        'الترجمة إلى': 'Translate to',
        'ملفات ذات صلة': 'Related files',
        'لا يوجد ملفات ذات صلة': 'No related files',
        'رابط ملف ذا صلة': 'Related file link',
        'جاري النسخ ...': 'Copying ...',
        'تم النسخ بنجاح': 'Copied successfully',
        'بواسطة الذكاء الاصطناعي': 'By AI',
        'عرض المزيد': 'Show more',
        'أصل الوثيقة': 'Original Document',
        'رابط اصل الوثيقة': 'Document origin link',
        'للمزيد سجل الدخول': 'For more, log in',
        'مشاركة المحتوى': 'Share content',
        'نسخ المحتوى': 'Copy content',
        'اطلع على': 'View',
        'في منصة قانونية': 'On Qanoniah platform',
        'من': 'From',
        'التعديلات السابقة من': 'Previous edits from',
        'المناقشات': 'Discussions',
        'اذهب للإعدادات': 'Go to settings',
        'تعرف على ميزة المناقشات': 'Learn about the discussions feature',
        'توجد رسائل جديدة': 'There are New messages',
        'رسائل غير مقروءة': 'Unread messages',
        'إعادة التسمية': 'Rename',
        'انت': 'You',
        'حذف مناقشة': 'Delete discussion',
        'هل انت متأكد من حذف مناقشة': 'Are you sure you want to delete the discussion?',
        'تحديث مناقشة': 'Update discussion',
        'مناقشة جديدة': 'New discussion',
        'تحديث': 'Update',
        'إنشاء': 'Create',
        'يمكنك تفعيل ميزة المناقشات من الاعدادات': 'You can activate the discussions feature from the settings',
        'يمكن لقائد الفريق تفعيل ميزة المناقشات': 'The team leader can activate the discussions feature',
        'لم يتم ايجاد اي مناقشات مفتوحة.': 'No open discussions found.',
        'لم يتم ايجاد اي مناقشات مغلقة.': 'No closed discussions found.',
        'لم يتم ايجاد اي مناقشات في هذا الملف.': 'No discussions found in this file.',
        'يمكنك الرد أو اضافة مستخدمين اخرين @': 'You can reply or add other users @',
        'نص الرسالة مطلوب.': 'Message text is required.',
        'فتح': 'Open',
        'هذا الملف': 'This file',
        'خيارات اضافية': 'Additional options',
        'اليوم': 'Today',
        'بالأمس': 'Yesterday',
        'عنوان النقاش': 'Discussion title',
        'لم يتم ايجاد الملف': 'File not found',
        'ليس لديك صلاحية الوصول.': 'You do not have access.',
        'لقد حصل خطأ ما.': 'An error occurred.',
        'لقد حصل خطأ اثناء تحديث الملاحظة': 'An error occurred while updating the note',
        'جار النسخ': 'Copying',
        'حصل خطأ الرجاء المحاولة مرة اخرى': 'An error occurred, please try again',
        'عارض الملفات': 'File viewer',
        'تمت الاضافة بنجاح': 'Successfully added',
        'حصل خطأ اثناء اضافة الملف الى مجلداتك': 'An error occurred while adding the file to your folders',
        'لا يسمح بتحميل الملفات اثناء فترة التجربة': 'File uploads are not allowed during the trial period',
        'الكل': 'All',
        'المفتوحة': 'Opened',
        'المغلقة': 'Closed',
        'اعرف اكثر': 'Learn more',
        'رابط الملف': 'File link',
        'عرض في مجلداتي': 'Show in my folders',
        'ميزة الروابط التشعبية تتطلب تسجيل الدخول': 'Hyperlinks feature requires logging in',
        'لا يوجد ترجمة رسمية حتى الان من قبل الجهة الرسمية': 'There is no official translation yet by the Official Authority',
        'file viewer': {
            'file info': 'File info'
        },
        // Counter
        'counter': {
            'dateText': 'On date {date}',
            'link message': 'Learn more',
            'link label': 'File link',
            'content': 'This content is not available for your package',
            'upgrade': 'Upgrade your package to view the content',
            'show more': 'Show more'
        },

        // contracts
        'contracts page': 'Contracts & Templates',
        'contracts predefined templates': 'Predefined templates',
        'contracts create new contract': 'Create new contract',
        'contracts search': 'Search',
        'contracts three characters minimum': 'Three characters minimum',
        'contracts all': 'All',
        'contracts other': 'Other',
        'contracts no results': 'No results',
        'contracts answer questions': 'Answer the following questions to create the required contract/template!',
        'contracts subject': 'Contract/Template Subject',
        'contracts view contract': 'View Contract/Template',
        'contracts loading template': 'Loading template',
        'contracts loading contract': 'Loading Contract/Template',
        'contracts save copy': 'Save copy',
        'contracts save': 'Save',
        'contracts save here': 'Save here',
        'contracts not found': 'Contract/Template not found',
        'contracts no access': 'You do not have access.',
        'contracts other clause formats': 'Other formats',
        'contracts clause clarification': 'Clarification',
        'contracts remove clause': 'Remove clause',
        'contracts add clause': 'Add clause',
        'contracts clarification for clause': 'Clarification for clause',
        'contracts more information about subject': 'For more information about the subject:',
        'contracts related templates': 'Related templates:',
        'contracts select clause format': 'Select format',
        'contracts use template': 'Use template',
        'contracts back to questions': 'Back to questions',
        'contracts new version available': 'New version available',
        'contracts save a copy with new version': 'Save a copy with new version',
        'contracts download': 'Download',
        'contracts print': 'Print',
        'contracts copy': 'Copy',
        'contracts inputs': 'Inputs',
        'contracts file': 'File',
        'contracts approve': 'Approve',
        'contracts contract template': 'Contract/Template',
        'contracts edit': 'Edit',
        'contracts confirm lock message': 'Are you sure you want to proceed to the approval page without completing the contract inputs?',
        'contracts yes': 'Yes',
        'contracts complete inputs': 'Complete contract inputs',
        'contracts new version': 'New version',
        'contracts use new version': 'I want to use the new version',
        'contracts print settings': 'Print Settings',
        'contracts reset default values': 'Reset Default Values',
        'contracts print font type': 'Font type',
        'contracts print font size': 'Font size',
        'contracts print page numbering': 'Page numbering',
        'contracts print top margin': 'Top margin',
        'contracts print bottom margin': 'Bottom margin',
        'contracts print right margin': 'Right margin',
        'contracts print left margin': 'Left margin',
        'contracts print cover': 'Contract/Template cover',
        'contracts print cover description': 'The contract/template cover will be applied on the first page',
        'contracts print header': 'Company official letterhead',
        'contracts print header description': 'The official letterhead will be applied to all pages of the contract/template',

        // AI
        'ai gpt page': 'AI',

        // Workers
        'workers page': 'Workers',
        'workers add new worker': 'Add New Worker',
        'workers search here': 'Search Here',
        'workers search': 'Search',
        'workers select current page': 'Select Current Page',
        'workers select all': 'Select All',
        'workers selected': 'Selected',
        'workers name': 'Name',
        'workers creation': 'Creation',
        'workers no search results': 'No search results',
        'workers no workers added': 'No workers added yet',
        'workers error message': 'An error occurred, please try again later',
        'workers remove worker': 'Remove Worker',
        'workers add one new worker': 'Add one new worker',
        'workers add multiple new workers': 'Add multiple workers',
        'workers added multiple new members successfully': 'Successfully added multiple workers',
        'workers delete workers': 'Delete workers',
        'workers confirm deleting workers': 'Are you sure you want to delete {count} workers?',

        // Subscription
        'subscriptions page': 'Subscriptions',

        // Settings
        'settings page': 'Settings',
        'settings account settings': 'Account Settings',
        'settings manage your data': 'You can manage all your personal data from here',
        'settings choose from list': 'Choose from the list:',
        'settings contracts and templates': 'Contracts and Templates',
        'settings profile': 'Profile',
        'settings security': 'Security',
        'settings notifications': 'Notifications',
        'التحكم في الإشعارات من هنا': 'Control notifications from here',
        'لا توجد إشعارات قابلة للتخصيص بعد': 'No customizable notifications yet',
        'إشعارات عن طريق': 'Notifications via',
        'الغير مقروءة': 'Unread',
        'لا توجد إشعارات غير مقروءة بعد': 'No unread notifications yet',
        'لا توجد إشعارات بعد': 'No notifications yet',
        'سنخبرك عندما يكون لديك أي تحديثات هنا': 'We will notify you when you have any updates here',
        'settings coupon': 'Coupon',
        'settings save changes': 'Save Changes',
        // Profile personal info
        'settings profile personal info page': 'Personal Information',
        'settings profile personal info desc': 'You can update your personal information here',
        // profile image
        'settings profile image page': 'Profile Image',
        'settings profile image desc': 'You can update your profile image here',
        // profile email
        'settings profile email page': 'Profile Email',
        'settings profile email desc': 'You can update your email here',
        'settings verified': 'Verified',
        'settings not verified': 'Not verified',
        'settings verify': 'Check',
        'settings send verification code': 'Send verification code',
        'settings current password': 'Current password',
        // Security password
        'settings security password page': 'Password',
        'settings security password desc': 'You can change your password here',
        'settings security password current password': 'Current password',
        'settings security password new password': 'New password',
        'settings security password confirm new password': 'Confirm new password',
        'settings security password successfully updated': 'Successfully updated',
        // Security Trusted Devices
        'settings security trusted devices title': 'Trusted Devices',
        'settings security trusted devices description': 'Trust this device to log in easily and securely without two-step verification.',
        'settings security trusted devices trust this device': 'Trust this device',
        'settings security trusted devices current device': 'Current device',
        'settings security trusted devices remove device': 'Remove device',
        'settings security trusted devices trust this device confirmation': 'Two-step verification will not be required on this device, are you sure?',
        'settings security trusted devices remove device confirmation': 'Are you sure you want to remove the device?',
        'settings security trusted devices trust this device success': 'Device successfully added',
        'settings security trusted devices remove device success': 'Device successfully removed',
        // Security Two-Factor Authentication
        'settings security two factor title': 'Two-Factor Authentication',
        'settings security two factor description': 'Change the phone number to which the verification code will be sent when logging in',
        'settings security two factor phone number': 'Phone Number',
        'settings security two factor enter verification code sent to': 'Enter the verification code sent to',
        'settings security two factor send verification code via sms': 'Send verification code via SMS',
        'settings security two factor send verification code via whatsapp': 'Send verification code via WhatsApp',
        'settings security two factor phone number updated successfully': 'Phone number updated successfully.',
        // Settings Notifications
        'settings notifications title': 'Notifications',
        'settings notifications description': 'Enable or disable notifications.',
        'settings notifications no customizable notifications': 'No customizable notifications yet',
        'settings notifications other': 'Other',
        'settings notifications save': 'Save',
        'settings notifications successfully updated': 'Successfully updated',
        // Settings Coupons
        'settings coupons title': 'Coupons',
        'settings coupons coupon code': 'Coupon Code',
        'settings coupons apply': 'Apply',
        'settings coupons success': 'Coupon applied successfully',
        // Settings Team
        'settings team title': 'Team',
        'settings team description': 'Team and members-related options',
        'settings team show member notes': 'Show member notes',
        'settings team show member notes tooltip': 'Show member notes to other members while browsing files',
        'settings team enable instant discussions': 'Enable instant discussions',
        'settings team enable instant discussions tooltip': 'If instant discussions are enabled, discussions will be shared with all team members',
        'settings team save': 'Save',
        'settings team successfully updated': 'Successfully updated',
        // Settings Contracts cover
        'settings contracts cover title': 'Contract/Template Cover',
        'settings contracts cover description': 'The cover of the contract/template is the first page of it',
        'settings contracts cover remove': 'Remove',
        'settings contracts cover success': 'Successfully updated',
        'settings contracts cover confirm remove': 'Remove the cover?',
        // Settings Contracts bg
        'settings contracts bg header title': 'Company Official Letterhead',
        'settings contracts bg header description': 'The official letterhead will be applied to all pages of the contract/template',
        'settings contracts bg remove': 'Remove',
        'settings contracts bg success': 'Successfully updated',
        'settings contracts bg confirm remove': 'Remove the official letterhead?',

        // Notifications
        'notifications page': 'Notifications',

        // Teams
        'team page': 'Team',
        'team members added multiple new members successfully': 'Successfully added multiple members',
        'team members page': 'Team Members Management',
        'team members add new member': 'Add new member',
        'team members remove member': 'Remove member',
        'team members': 'Team Members',
        'team members resend invitation successfully': 'Invitation resent successfully',
        'team members count usage': '{users_count} of {total_seats}',
        'team members search': 'Search here',
        'team members search no results': 'No results found',
        'team members not yet added': 'No members added yet',
        'team members invitation status accepted': 'Accepted',
        'team members invitation status pending': 'Pending',
        'team members add one new member': 'Add one new member',
        'team members add multiple new members steps': 'Step {current} of {total}',
        'team members add multiple new members': 'Add multiple members',
        'team members send invitation': 'Send',
        'team members resend invitation': 'Resend activation email',
        'team members removed member': 'Removed member',
        'team members successfully': 'Successfully',
        'team members copy invitation link': 'Copy invitation link',
        'team members copied invitation link': 'Invitation link copied',
        'team members added new member successfully and sent activation link': 'Member created successfully and activation email sent',
        'team members remove member confirmation': 'Are you sure you want to remove the member',

        // inputs
        'input file upload drag file or': 'Drag file or',
        'input file upload choose file': 'Choose file',
        'input file upload click here': 'Click here',
        'input file upload to remove files': 'To remove files',

        // upload entities
        'upload entities upload file': 'Upload file',
        'upload entities add multiple new members upload your own file': 'Upload your own file',
        'upload entities add multiple new members download qanoniah template then upload the file': 'Download Qanoniah template then upload the file',
        'upload entities download the template to insure a proper upload': 'Download the template to insure a proper upload',
        'upload entities already downloaded csv template': 'Already downloaded CSV template',
        'upload entities data in file': 'Data in file',
        'upload entities data reviewed': 'Data reviewed',
        'upload entities dont import': 'Don\'t import',
        'upload entities column name in file': 'Column name in file',
        'upload entities column data in file': 'Data in file',
        'upload entities qanoniah format': 'Qanoniah format',
        'upload entities download csv template': 'Download CSV template',
        'upload entities not reviewed yet': 'Not reviewed yet',
        'upload entities you have': 'You have',
        'upload entities i agree to import data without reviewing': 'I agree to import data without reviewing',
        'upload entities add multiple new members steps': 'Step {current} of {total}',
        'upload entities add multiple new members step type': 'Type',
        'upload entities add multiple new members step upload': 'Upload',
        'upload entities add multiple new members step review data': 'Review Data',
        'upload entities add multiple new members how to get started': 'How do you want to get started?',
        'upload entities uploading file': 'Uploading file',

        // general
        'general error': 'Something went wrong',
        'general error please try again': 'An error occurred, please try again',
        'this content is not available for your package': 'This content is not available for your package',
        'confirm': 'Confirm',
        'in': 'in',
        'copy': 'Copy',
        'close': 'Close',
        'cancel': 'Cancel',
        'from': 'from',
        'next': 'Next',
        'back': 'Back',
        'success': 'Success',
        'delete': 'Delete',
        'edit': 'Edit',
        'first name': 'First Name',
        'last name': 'Last Name',
        'user name': 'Name',
        'user full name': 'Full Name',
        'user phone': 'Phone',
        'user email': 'Email',
        'تسجيل الدخول': 'Login',
        'البريد الإلكتروني': 'Email',
        'كلمة المرور': 'Password',
        'تأكيد كلمة المرور': 'Confirm Password',
        'تذكرني': 'Remember me',
        'عند الموافقة استمتع بتسجيل دخول أطول وأكثر راحة على نفس المتصفح': 'Enjoy a longer and more comfortable login on the same browser when approved',
        'هل نسيت كلمة المرور ؟': 'Forgot your password?',
        'ليس لديك حساب ?': 'Don\'t have an account?',
        'سجل الآن': 'Register now',
        'إنشاء حساب': 'Register',
        'التحقق بخطوتين': 'Two-Factor Authentication',
        'ادخال رقم التحقق المرسل لرقم الهاتف الخاص بكم:': 'Enter the verification code sent to your phone number:',
        'العودة الى تسجيل الدخول': 'Back to login',
        'رقم التحقق:': 'Verification Code:',
        'إعادة إرسال رقم التحقق عن طريق الرسائل النصية': 'Resend verification code via SMS',
        'إعادة إرسال رقم التحقق عن طريق الواتساب': 'Resend verification code via WhatsApp',
        'تحقق': 'Verify',
        'لقد تم إعادة إرسال رقم التحقق عن طريق الرسائل النصية بنجاح.': 'Verification code has been resent via SMS successfully.',
        'لقد تم إعادة إرسال رقم التحقق عن طريق الواتساب بنجاح.': 'Verification code has been resent via WhatsApp successfully.',
        'ألقِ نظرة على آخر تحديثات قانونية': 'Take a look at the latest Qanoniah updates',
        'عرض كل التحديثات': 'View all updates',
        'نوع الحساب': 'Account Type',
        'الاسم الاول': 'First Name',
        'الاسم الاخير': 'Last Name',
        'رقم الجوال': 'Mobile Number',
        'كوبون الدعوة (اختياري)': 'Invitation Coupon (Optional)',
        'بالتسجيل فأنا أوافق على': 'By registering, I agree to',
        'سياسات منصة قانونية': 'Qanoniah platform policies',
        'تغيير': 'Change',
        'تغيير رقم الجوال': 'Change Mobile Number',
        'قم بإدخال رقم الجوال الخاص بك': 'Enter your mobile number',
        'أدخل رمز التحقق': 'Enter verification code',
        'تم ارسال الرمز في رسالة نصية إلى رقم الجوال': 'The code has been sent in a SMS message to the mobile number',
        'تأكيد': 'Confirm',
        'إعادة ارسال رقم التحقق عن طريق': 'Resend verification code via',
        'واتساب': 'WhatsApp',
        'رسالة نصية': 'SMS',
        'تنتهي صلاحية الرمز خلال': 'The code expires in',
        'حساباتي': 'My Accounts',
        'الحالي': 'Current',
        'هذا المحتوى غير متاح للباقة الخاصة بك': 'This content is not available for your package',
        'قم بترقية الباقة لعرض المحتوى': 'Upgrade the package to view the content',

        'العودة للوحة التحكم': 'Back to Dashboard',
        'تحديثات المنصة': 'Platform Updates',
        'ابحث هنا...': 'Search here...',
        'مقالات': 'Articles',

        // layouts
        'الاعدادات': 'Settings',
        'الاشتراك': 'Subscription',
        'الفريق': 'Team',
        'تسجيل الخروج': 'Logout',
        'لماذا قانونية': 'Why Qanoniah',
        'شركاء النجاح': 'Success Partners',
        'تواصل معنا': 'Contact Us',
        'المعرفة': 'Knowledge',
        'مدونة قانونية': 'Qanoniah Blog',
        'من نحن؟': 'About Us',
        'لماذا قانونية؟': 'Why Qanoniah?',
        'سياسة الخصوصية و الاستخدام': 'Privacy Policy and Usage',
        'جميع الحقوق محفوظة لشركة عدالة لتقنية نظم المعلومات ذ.م.م. © 2025': 'All rights reserved to Adalah Information Systems LLC. © 2024',
        'الرقم الضريبي 310887667800003': 'Tax Number 310887667800003',

        // landing
        'قانونية.. خيارك الأول لعملية بحث قانونية متكاملة': 'Saudi law is easily accessible through Qanoniah',
        'ابحث في أكثر من 90,000 مستند قانوني سعودي في ثوانٍ.': 'Get fast and accurate answers to any Saudi legal question in seconds with the largest legal platform in the Kingdom of Saudi Arabia.',
        'جرب المنصة مجاناً': 'FREE TRIAL',
        'إدارات قانونية في كبرى الشركات والجهات تثق في قانونية': 'Trusted by Leading Companies and Government Entities',
        'رسخت مكانتنا كشريك موثوق به للشركات الرائدة في مختلف القطاعات.': 'Leading companies and government entities in Saudi Arabia trust and use Qanoniah platform for legal research.',
        'ماهي قانونية؟': 'What is Qanoniah?',
        'البحث القانوني': 'Legal search',
        'قاعدة بيانات نوعية تحوي أكثر من ٩٠ ألف مستند سعودي': 'A qualitative database containing more than 90,000 Saudi documents',
        'منصة بحث قانونية توفر حلاً شاملاً لجميع احتياجاتك البحثية حيث تشمل مجموعة واسعة من المستندات القانونية، من خلال توفير قاعدة بيانات نوعية تحتوي على أكثر من 90 ألف مستند قانوني سعودي، كما أنها تتضمن تقنيات متقدمة تساعد في الوصول والفهم والتعامل مع المعلومات القانونية.': 'A legal search platform that provides a comprehensive solution for all your research needs, including a wide range of legal documents, by providing a qualitative database containing more than 90,000 Saudi legal documents. It also includes advanced technologies that help in accessing, understanding, and dealing with legal information.',
        'قانونية مصدرك الأشمل': 'Why Qanoniah?',
        'أكثر من 90 ألف مستند قانوني سعودي': 'Advanced features that make Qanoniah your top choice of legal platform',
        'كيف تميزت قانونية': 'How Qanoniah excelled',
        'قانونية في أرقام': 'Qanoniah in Numbers',
        'نُقدم حلولًا قانونية فعالة تلبي احتياجات عملائنا المتنوعة': 'We offer legal solutions that effectively meet our clients\' diverse needs',
        'آراء عملاء قانونية': 'Qanoniah customer reviews',
        'نسعد بتلبية احتياجات عملائنا القانونية وتوفير أفضل تجربة بحثية لهم': 'We are pleased to meet the legal needs of our clients and provide them with the best research experience',
        'نسعى في المدونة إلى إثراء المجال القانوني وتقديم الفائدة من خلال طرح مقالات تناقش أبرز المواضيع': 'We strive in the blog to enrich the legal field and provide benefit by presenting articles that discuss the most prominent topics',
        'الأسئلة الشائعة': 'FAQs',
        'نهتم بتقديم إجابات شاملة لبدء تجربة بحث مميزة': 'We care about providing comprehensive answers to start a distinctive search experience',
        'تجربة البحث الفريدة': 'Qanoniah ... Legal search smarter than ever',
        'تبدأ مع قانونية': 'starts with Qanoniah',
        'سجّل الآن للوصول إلى محتوى قانوني متخصص ولأجوبة موثوقة لكل أسئلتك القانونية': 'Register now to access specialized legal content and reliable solutions to all your legal questions and matters.',
        'منتج الموارد البشرية': 'HR product',
        'الموارد البشرية': 'Human Resources',
        'بوابة الوصول إلى كل ما تحتاجه لإدارة مواردك البشرية بفعالية': 'A gateway to access everything you need to manage your human resources effectively',
        'تقارير قانونية': 'Qanoniah Reports',
        'القطاعات المستهدفة': 'Targeted sectors',
        'المحامين': 'Lawyers',
        'قانونية': 'Qanoniah',
        'شركات المحاماة': 'Law firms',
        'الشركات': 'Companies',
        'المنتجات': 'Products',
        'تعرف على منتج الموارد البشريةً': 'Learn about the HR product',
        'وصف منتج الموارد البشرية': 'It is a package specifically designed to help HR departments in Saudi companies avoid fines, reduce legal costs, and comply with Saudi regulations. Qanoniah HR enables you to access, understand, and handle the largest Saudi legal database specialized in Saudi labor laws. Additionally, it includes detailed explanations of various regulatory procedures step-by-step, and a contract and form program containing more than 100 legally reviewed and compliant contracts and forms.',
        'هي باقة مصممة خصيصًا لمساعدة إدارات الموارد البشرية في الشركات السعودية على تجنب الغرامات وتخفيض التكاليف القانونية والالتزام بالأنظمة السعودية ، حيث تمكنك قانونية HR من الوصول والفهم والتعامل مع أكبر قاعدة بيانات قانونية سعودية مختصة بأنظمة العمل السعودي، بالإضافة إلى شروحات تفصيلية لمختلف الإجراءات النظامية خطوة بخطوة، وبرنامج عقود ونماذج يحتوي على أكثر من ١٠٠ عقد ونموذج مدقق قانونيًا ومتوافق مع الأنظمة السعودية.': 'It is a package specifically designed to help HR departments in Saudi companies avoid fines, reduce legal costs, and comply with Saudi regulations. Qanoniah HR enables you to access, understand, and deal with the largest Saudi legal database specialized in Saudi labor systems, in addition to detailed explanations of various regulatory procedures step by step, and a contract and form program containing more than 100 legally audited contracts and forms compliant with Saudi regulations.',
        'أحكام إجازة يوم التأسيس للقطاع الخاص': 'Provisions for Foundation Day leave for the private sector',
        'وقت القراءة:  4 دقيقة أحكام إجازة يوم التأسيس للقطاع الخاص هذا المستند يوضح أحكام إجازة يوم التأسيس لموظفي القطاع الخاص أو العام الخاضعين لأحكام نظام العمل ولوائحه، وهو كل': 'Reading time: 4 minutes. Provisions for Foundation Day leave for the private sector. This document explains the provisions for Foundation Day leave for private or public sector employees subject to the provisions of the Labor Law and its regulations, which is all',
        'قراءة المزيد': 'Read more',
        'قانونية واحدة من أكبر قواعد البيانات القانونية في المملكة العربية السعودية، تجمع أكثر من 60 موقع قانوني في مكان واحد، وتوفر الوصول السريع والموثوق للمعلومات القانونية المتنوعة.': 'Qanoniah is one of the largest legal databases in the Kingdom of Saudi Arabia, bringing together more than 60 legal sites in one place, and providing quick and reliable access to a variety of legal information.',
        'قانونية هي منصة بحثية قانونية توفر أكثر من 90 ألف مستند قانوني سعودي، تمكنك من الوصول إلى معلومات قانونية موثوقة ومحتوى متخصص في مختلف المجالات القانونية.': 'Qanoniah is a legal research platform that provides more than 90,000 Saudi legal documents, enabling you to access reliable legal information and specialized content in various legal fields.',
        'الأنظمة ولوائحها': 'Laws and regulations',
        'تجد في مكان واحد جميع الأنظمة السعودية مقترنةً بلوائحها التنفيذية وبآخر التحديثات والتعديلات الصادرة عليها': 'Find all Saudi systems in one place, linked to their executive regulations, with the latest updates and amendments issued on them',
        'السوابق القضائية': 'Judicial precedents',
        'الاطلاع على آلاف من الأحكام القضائية في المملكة العربية السعودية مع إمكانية البحث بموضوع النزاع': 'Access thousands of Saudi Arabian court rulings, searchable by dispute subject.',
        'الأوامر والتعاميم': 'Orders and Circulars',
        'الاطلاع على آلاف الأوامر والقرارات بوثائقها الأصلية من جميع الجهات الحكومية مع إمكانية البحث في محتوى المستند': 'View thousands of orders and decisions from all government agencies, along with their original documents. You can search the content of each document as well.',
        'الكتب والأبحاث القانونية': 'Legal Books and Research',
        'تصفّح العديد من الكتب والأبحاث القانونية والمصادر الثانوية المتنوعة والمثرية': 'Explore a wide range of books, Qanoniah research, and secondary sources that entities in Saudi Arabia rely on for legal research through the Qanoniah platform.',
        'ماذا يميّز قانونية عن غيرها؟': 'What sets Qanoniah apart from others?',
        'قانونية أكبر قاعدة بيانات قانونية سعودية، جمعت أكثر من 60 موقع في مكان واحد!': 'Qanoniah stands out as the largest Saudi legal database, offering a convenient one-stop platform that combines over 60 government websites.',
        'هل يتم تحديث المستندات في قانونية بشكل فوري؟': 'Are documents in Qanoniah updated immediately?',
        'نعم، فريقنا يعمل على إضافة التحديثات والتعديلات بشكل فوري، مع إضافة تاريخ التعديل أو التحديث.': 'Yes, we update our Legal documents right away, including the date of modification or update.',
        'هل جميع السوابق القضائية منشورة؟': 'Are all judicial precedents published?',
        'نعم، تتميّز قانونية بجمعها لجميع السوابق القضائية المنشورة، كالأحكام الإدارية، العمالية، التجارية، العامة، الجزائية، التأمينية، الضريبية والجمركية والزكوية، وغيرها من الأحكام.': 'Yes Qanoniah stands out for collecting all types of published judicial precedents, including administrative, labor, commercial, public, criminal, insurance, tax, customs, and zakat rulings, among others.',
        'هل تحتوي منصة قانونية على كتب ومصادر ثانوية؟': 'Does Qanoniah platform contain books and secondary sources?',
        'بكل تأكيد! منصّة قانونية تجمع ما يزيد عن 180 كتاب بالإضافة إلى أبحاث ومقالات وشروحات تساعدك على الوصول للمعلومة بشكل أسرع.': 'Absolutely! Qanoniah platform gathers more than 180 books in addition to research, articles, and explanations to help you access information faster.',
        'هل يمكنني إضافة تعليقي الخاص على المستندات؟': 'Can I add my own comment on the documents?',
        'من مزايا منصة قانونية سهولة التعامل مع النص! وبكل سهولة يمكنك تحديد النص المطلوب وتظليله وإضافة ملاحظتك أو تعليقك وسيتم جمعها في " تحديداتي وملاحظاتي ".': 'One of the features of Qanoniah platform is the ease of handling text! You can easily select the required text, highlight it, and add your note or comment, which will be collected in "My Selections and Notes".',
        'مالفرق بين اشتراك الأفراد والمنشآت؟': 'What is the difference between individual and enterprise subscriptions?',
        'يتميز اشتراك المنشآت بمزايا الفريق، ومنها: المجلدات المشتركة داخل المنصة، والنقاشات بين أعضاء الفريق، ومدير الحساب الخاص، وتقديم ورشة تعريفية لضمان أكبر استفادة من المنصة.': 'Enterprise subscription is distinguished by team features, including shared folders within the platform, discussions between team members, a dedicated account manager, and an introductory workshop to ensure maximum benefit from the platform.',
        'كيف أبحث عن المستندات في قانونية؟': 'How do I search for documents in Qanoniah?',
        'يمكنك تجربة كتابة "نظام العمل" في محرك البحث، وسيظهر لك في أول نتيجة نظام العمل مرتبط بلائحته التنفيذية وبترجمة المواد، وستجد بجانب كل مادة المبادئ العمالية التي ناقشتها.': 'Try searching for "Labor Law" in the search engine. You\'ll see the Labor Law as the first result, along with its executive regulations. Each article will also display the related labor principles that discuss it.',
        'هل يمكنني البحث عن الأوامر والتعاميم في قانونية؟': 'Can I search for orders and circulars in Qanoniah?',
        'بكل تأكيد! منصة قانونية تمكنّك من الوصول إلى عدد كبير من الأوامر والتعاميم، ويمكنك البحث عنها بأحد الطرق التالية: رقم المستند، تاريخ المستند، الجهة المصدّرة للمستند، أي عبارة ذُكرت داخل المستند.': 'Absolutely! Qanoniah platform enables you to access a large number of orders and circulars, and you can search for them in one of the following ways: document number, document date, issuing authority, any phrase mentioned within the document.',
        'كيف أبحث عن السوابق القضائية؟': 'How do I search for judicial precedents?',
        'يمكنك البحث عن السوابق القضائية بالكلمات المفتاحية، أو موضوع قضية، أو كتابة رقم المادة واسم النظام في محرّك البحث، للتجربة اكتب: المادة ٦٧ نظام المرافعات الشرعية، وستظهر لك السوابق القضائية التي ناقشت المادة.': 'You can search for judicial precedents by keywords, case subject, or by typing the article number and the name of the system in the search engine. For example, type: Article 67 of the Law of Sharia Pleadings, and the judicial precedents that discussed the article will appear.',
        'هل توفرون خدمات تقسيط الاشتراك؟': 'Do you offer installment subscription services?',
        'نعم، يمكنك تقسيم اشتراكك على 4 دفعات مع تمارا.': 'Yes, you can divide your subscription into 4 payments with Tamara.',
        'كيف يمكنني التواصل معكم؟': 'How can I contact you?',
        'نسعد ونتشرف بك، ويمكنك التواصل معنا عبر الرابط': 'We\'re pleased and honored to have you. You can contact us by clicking this link:',
        'اضغط هنا': 'Click here',
        'ربط الأنظمة بلوائحها التنفيذية': 'Linking Laws to their executive regulations',
        'ربط النظام بلائحته التنفيذية لتمكن الباحث من استعراضهما معاً في الوقت نفسه.': 'Linking laws to their executive regulations allows researchers to review them together simultaneously.',
        'مستندات محدثة': 'Updated documents',
        'تصدر تحديثات وتعديلات عديدة على الأنظمة، وهنا تكمن مهمة قانونية بتسهيل الوصول إلى هذه المستجدات بتفاصيلها، وذلك بتوفير سجل كامل بجميع التعديلات الواردة عليها.': 'Laws often change and get modified. Qanoniah\'s mission is to simplify access by providing a complete record of all amendments and their details, making these updates available.',
        'خيارات بحث متقدمة مع إمكانية التخصيص': 'Advanced Search Options with Customization',
        'تمكّن من الوصول إلى المعلومات والمستندات القانونية بشكل دقيق وسريع.': 'Get quick and accurate access to legal information and documents by filtering search options to fit your specific needs.',
        'التظليل وتدوين الملاحظات': 'Highlighting and note-taking',
        'تمكنك قانونية من تدوين ملاحظاتك وتعليقاتك على المستندات مع إمكانية الرجوع إليها بكل سهولة.': 'Let you write notes and comments on documents, making it easy to refer back to them.',
        'المناقشات بين فريق العمل': 'Discussions between the team',
        'لرفع جودة الاحترافية والمهنية، شارك وناقش مع فريق عملك المعلومة القانونية مباشرةً من خلال منصة قانونية.': 'To improve professionalism and expertise, share legal information with your team and discuss it directly on the Qanoniah platform.',
        'مجلداتي': 'My Folders',
        'أنشئ مجلداتك الخاصة بك وأضف لها جميع المستندات ذات الأهمية، وارجع لها بكل سهولة في أي وقت.': 'Create your own folders, add important documents to them, and easily refer to them anytime.',
        'ترجمة معتمدة من قبل الجهات الرسمية': 'Certified Translation by Authorities',
        'احصل على الترجمات الرسمية والمعتمدة مباشرة من السلطات الحكومية، مع ضمان الدقة والموثوقية في كل التفاصيل.': 'Access official and certified translations directly from government authorities, ensuring accuracy and reliability in every detail.',
        'ابق على اطلاع بالقوانين السعودية': 'Stay Updated with Saudi Laws',
        'احصل على آخر التحديثات المتعلقة بالقوانين والأنظمة السعودية فور حدوثها، والتي يتم تسليمها مباشرة عبر البريد الإلكتروني والإشعارات داخل المنصة، مما يبقيك مطلعًا ومتقدمًا في جميع الأوقات.': 'Get the latest updates on Saudi laws and regulations as they happen, delivered directly via email and notifications within the platform, keeping you informed and ahead at all times.',
        'نشاط العملاء': 'Customer activity',
        'حجم التوفير من التكاليف القانونية المباشرة من خلال استخدام قانونية': 'Direct legal cost savings from using Qanoniah',
        'إجمالي ساعات العمل التي تم توفيرها من خلال استخدام قانونية': 'Total working hours saved through using Qanoniah',
        'ر.س': 'SAR',
        'الإحصائيات': 'Statistics',
        'باقات الاشتراك': 'Packages',
        'آراء العملاء': 'Customer Reviews',
        'خيارات متنوعة من اللغة الانجليزية': 'Certified Translation by Authorities',
        'توفر لك قانونية تجربة متنوعة من خيارات اللغة الإنجليزية الرسمية المعتمدة من قبل الجهات الرسمية، بالإضافة إلى الترجمة المدعومة بالذكاء الاصطناعي. مما يوفر لك مستوى عالٍ من الدقة والموثوقية': 'Access official and certified translations directly from government authorities, ensuring accuracy and reliability in every detail.',
        'كن مطلع على آخر تحديثات القوانين واللوائح السعودية مباشرة من خلال ميزة الإشعارات عن طريق إشعارات الرسائل النصية و المنصة و البريد الإلكتروني ، مما يبقيك على اطلاع دائم على التحديثات': 'Get the latest updates on Saudi laws and regulations as they happen, delivered directly via email and notifications within the platform, keeping you informed and ahead at all times.',

        // changelogs
        'سجل التحديثات': 'Changelogs',

        // Contact Us
        'الدردشة': 'Chat',
        'عن طريق برنامج واتساب': 'Via WhatsApp',
        'عن طريق الموقع': 'Via website',
        'اضغط هنا للدردشة': 'Click here to chat',
        'طلب تواصل (قطاع خاص / جهات حكومية)': 'Contact Request (Private Sector / Government Entities)',

        // Reports
        'حمّل الدليل التشريعي': 'Download the Legislative Guide',
        'الاسم بالكامل': 'Full Name',
        'المسمى الوظيفي': 'Job Title',
        'البريد الالكتروني الخاص بالشركة': 'Company Email',
        'حمّل الان': 'Download Now',
        'على ماذا يحتوي التقرير؟': 'What does the report contain?',
        'بالطلب فأنا أوافق على': 'By requesting, I agree to',
        'تم التحميل بنجاح': 'Downloaded successfully',
        'نشكرك على تحميلك لـ': 'Thank you for downloading',
        'ونأمل أن يساعدك على تعزيز البيئة القانوينة في شركتك ويكون لك عونًا في الالتزام بالقوانين والضوابط الخاصة بالانظمة !': 'We hope that it will help you enhance the legal environment in your company and assist you in complying with the laws and regulations of the regulations!',

        // Subscription
        'تجديد الاشتراك': 'Renew Subscription',
        'تغيير الباقة': 'Change Package',
        'عرض الباقات': 'View Packages',
        'ترقية الباقة': 'Upgrade Package',
        'حالة الاشتراك': 'Subscription Status',
        'ساري': 'Active',
        'منتهي': 'Expired',
        'تاريخ التجديد': 'Renewal Date',
        'باقي': 'Remaining',
        'يوم': 'Days',
        'بيانات الدفع': 'Payment Information',
        'تغيير طريقة الدفع': 'Change Payment Method',
        'الاشتراكات المفعلة': 'Active Subscriptions',
        'سجل المشتريات': 'Purchase History',
        'إظهار كل المشتريات': 'Show All Purchases',
        'عرض الفاتورة': 'View Invoice',
        'النوع': 'Type',
        'تاريخ الاشتراك': 'Subscription Date',
        'رسوم الاشتراك': 'Subscription Fees',
        'اختر الباقة': 'Choose Package',
        'الباقة الحالية': 'Current Package',

        // Privacy Policy
        'سياسة الخصوصية': 'Privacy Policy',
        'سياسة الإستخدام': 'Usage Policy',
        'الاستخدام': 'Usage',
        'و': 'and',
        'سياسة الاستخدام': 'Usage Policy',
        'بيانات التواصل': 'Contact Information',
        'القسم/الفريق المختص': 'Department/Responsible Team',
        'القسم المسؤول عن تطبيق أحكام نظام حماية البيانات الشخصية في الشركة': 'The department in charge of applying the provisions of the Personal Data Protection System within the Company',
        'العنوان': 'Address',
        'المملكة العربية السعودية، مدينة الرياض': 'Riyadh, Saudi Arabia',
        'رقم الهاتف': 'Phone Number',
        'السجل التجاري': 'Commercial Registration No',
        'البند الأول: مقدمة': 'Introduction',
        'privacy policy introduction part 1': '1.1 This policy outlines the personal data to be collected, the purpose of processing it, how it will be used, the legal basis for its collection and processing, the parties to whom the data may be disclosed (if any), the geographical scope of processing, the retention period of the data, and how it will be destroyed.'
    }
}
