
export default {
    name: 'Footer',
    props: {
        small: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            modal: null
        }
    }
}
