export default () => {
    return {
        // app
        'app name': 'قانونية',
        'home page': 'الرئيسية',
        'header manage team': 'الفريق',
        'header logout': 'تسجيل الخروج',

        // Search Page
        'search page title': 'البحث',
        'search here': 'ابحث هنا...',
        'what you want to search today': 'ما الذي تود البحث عنه اليوم؟',
        'search results type summary': 'ملخص',
        'search results type ai summary': 'ملخص (ذكاء اصطناعي)',
        'search results type content': 'محتوى',
        // Search Bar
        'remove search option': 'إزالة الخيار',
        'add search option': 'إضافة الخيار',
        'search type': 'نوع البحث',
        'please select search type first': 'قم بإختيار نوع البحث اولاً',
        'search type all': 'جميع النتائج',
        'search type verbatim': 'بحث حرفي',
        'search bar options': 'خيارات البحث',
        // search history
        'latest search operations': 'آخر عمليات البحث',
        'latest viewed search files': 'مستندات تم فتحها مؤخرا',
        // search filters
        'search filters': 'خيارات البحث',
        'search filters desc': 'يمكنك البحث في اكثر من نوع',
        'reset all search filters': 'إعادة ضبط الكل',
        'reset search filter': 'إعادة ضبط',
        // main page suggestions
        'show more suggestions': 'عرض المزيد',

        // help center
        'mini help center label': 'مركز التعلم',

        // highlights & notes
        'highlights and notes page': 'تحديداتي وملاحظاتي',
        'order hxn by': 'ترتيب حسب',
        'order hxn by asc': 'الأقدم',
        'order hxn by desc': 'الأحدث',
        'no hxn results': 'لا يوجد تحديدات او ملاحظات',
        'hxn new files': 'مضافة حديثاََ',
        'hxn recent files': 'تم فتحها مؤخراََ',
        'hxn recently highlighted': 'تم التحديد مؤخراََ',
        'hxn suggestion': 'الملفات المقترحة',

        // My Folders
        'my folders page': 'مجلداتي',
        'here shows the trashed files': 'تظهر هنا الملفات التي قمت بحذفها',
        'my folders empty folder placeholder': 'يمكنك إنشاء مجلد جديد او إضافة ملف إلى',
        'no search results for this search': 'لم يتم العثور على نتائج لـ',
        'folder not found': 'لم يتم العثور على المجلد المطلوب',
        'folder name': 'اسم المجلد',
        'back to my folders': 'العودة إلى مجلداتي',
        'my folders table header name': 'الاسم',
        'my folders trash bin': 'سلة المحذوفات',
        'my folders table header owner': 'المالك',
        'my folders table header creation': 'الانشاء',
        'my folders table header trash': 'الحذف',
        'my folders details type': 'النوع',
        'my folders create new folder': 'إنشاء',
        'my folders create new folder modal label': 'مجلد جديد',
        'my folders search in': 'ابحث في',
        'my folders show details': 'عرض التفاصيل',
        'my folders show in its folder': 'عرض في المجلد',
        'my folders open in new tab': 'فتح في نافذة جديدة',
        'my folders copy': 'نسخ',
        'my folders copy here': 'نسخ هنا',
        'my folders copy to': 'نسخ إلى',
        'my folders move here': 'نقل هنا',
        'my folders move to': 'نقل إلى',
        'my folders trash': 'حذف',
        'my folders delete': 'حذف',
        'my folders restore': 'استعادة',
        'my folders move': 'نقل',
        'my folders rename': 'إعادة تسمية',
        'my folders delete permanently': 'حذف نهائي',
        'my folders details location': 'الموقع',
        'my folders details original location': 'الموقع الأصلي',
        'my folders details last edit': 'اخر تعديل',
        'my folders details deletion time': 'وقت الحذف',
        'my folders me': 'أنا',
        'my folders delete file': 'حذف الملف',
        'my folders delete files': 'حذف الملف',
        'my folders delete files confirm': 'هل أنت متأكد من حذف الملف؟',
        'my folders are you sure you want to delete section 1': 'هل أنت متأكد من حذف',
        'my folders are you sure you want to delete section 2': 'من الملفات؟',
        'my folders delete file permanently': 'حذف الملف نهائيا',
        'my folders delete files permanently': 'حذف الملفات نهائيا',
        'my folders delete files permanently confirm': 'هل أنت متأكد من حذف الملف؟',
        'my folders are you sure you want to permanently delete section 1': 'هل أنت متأكد من حذف',
        'my folders are you sure you want to permanently delete section 2': 'من الملفات نهائيا؟',

        // File Viewer
        'file viewer loading file': 'جار عرض الملف',
        'file viewer add to': 'إضافة إلى',
        'file viewer add': 'إضافة',
        'file viewer add here': 'إضافة هنا',
        'file viewer copy text': 'نسخ النص',
        'file viewer copy': 'نسخ',
        'file viewer': {
            'file info': 'معلومات الملف'
        },
        // Counter
        'counter': {
            'dateText': 'في تاريخ {date}',
            'link message': 'اعرف اكثر',
            'link label': 'رابط الملف',
            'content': 'هذا المحتوى غير متاح للباقة الخاصة بك',
            'upgrade': 'قم بترقية الباقة لعرض المحتوى',
            'show more': 'عرض المزيد'
        },

        // contracts
        'contracts page': 'عقود ونماذج',
        'contracts predefined templates': 'القوالب الجاهزة',
        'contracts create new contract': 'إنشاء عقد جديد',
        'contracts search': 'ابحث',
        'contracts three characters minimum': 'ثلاث أحرف على الأقل',
        'contracts all': 'الكل',
        'contracts other': 'أخرى',
        'contracts no results': 'لا يوجد نتائج',
        'contracts answer questions': 'اجب على الاسئلة الاتية لإنشاء العقد/النموذج المطلوب!',
        'contracts subject': 'موضوع العقد/النموذج',
        'contracts view contract': 'عرض العقد/النموذج',
        'contracts loading template': 'جار عرض النموذج',
        'contracts loading contract': 'جار عرض العقد/النموذج',
        'contracts save copy': 'حفظ نسخة',
        'contracts save': 'حفظ',
        'contracts save here': 'حفظ هنا',
        'contracts not found': 'لم يتم ايجاد العقد/النموذج',
        'contracts no access': 'ليس لديك صلاحية الوصول.',
        'contracts other clause formats': 'صيغ أخرى',
        'contracts clause clarification': 'توضيح',
        'contracts remove clause': 'إزالة البند',
        'contracts add clause': 'إضافة البند',
        'contracts clarification for clause': 'توضيح للبند',
        'contracts more information about subject': 'للإطلاع على مزيد من المعلومات حول الموضوع:',
        'contracts related templates': 'نماذج ذات علاقة:',
        'contracts select clause format': 'اختيار صيغة',
        'contracts use template': 'استخدام النموذج',
        'contracts back to questions': 'الرجوع للأسئلة',
        'contracts new version available': 'يوجد إصدار جديد',
        'contracts save a copy with new version': 'حفظ نسخة بإصدار جديد',
        'contracts download': 'تنزيل',
        'contracts print': 'طباعة',
        'contracts copy': 'نسخ',
        'contracts inputs': 'مدخلات',
        'contracts file': 'الملف',
        'contracts approve': 'اعتماد',
        'contracts contract template': 'العقد/النموذج',
        'contracts edit': 'تعديل',
        'contracts confirm lock message': 'هل أنت متأكد من الانتقال لصفحة الاعتماد من غير إكمال مدخلات العقد ؟',
        'contracts yes': 'نعم',
        'contracts complete inputs': 'إكمال مدخلات العقد',
        'contracts new version': 'الإصدار الجديد',
        'contracts use new version': 'أرغب في استخدم الإصدار الجديد',
        'contracts print settings': 'إعدادات الطباعة',
        'contracts reset default values': 'استعادة القيم الإفتراضية',
        'contracts print font type': 'نوع الخط',
        'contracts print font size': 'حجم الخط',
        'contracts print page numbering': 'ترقيم الصفحات',
        'contracts print top margin': 'الهامش العلوي',
        'contracts print bottom margin': 'الهامش السفلي',
        'contracts print right margin': 'الهامش الأيمن',
        'contracts print left margin': 'الهامش الأيسر',
        'contracts print cover': 'غلاف العقد/النموذج',
        'contracts print cover description': 'سيتم تطبيق غلاف العقد/النموذج في الصفحة الأولى',
        'contracts print header': 'الترويسة الرسمية لأوراق الشركة',
        'contracts print header description': 'سيتم تطبيق الترويسة الرسمية على جميع صفحات العقد/النموذج',

        // AI
        'agent page': 'الذكاء الاصطناعي',

        // Workers
        'workers page': 'العاملين',
        'workers add new worker': 'إضافة عامل جديد',
        'workers search here': 'ابحث هنا',
        'workers search': 'ابحث',
        'workers select current page': 'تحديد الصفحة الحالية',
        'workers select all': 'تحديد الكل',
        'workers selected': 'محدد',
        'workers name': 'الاسم',
        'workers creation': 'الانشاء',
        'workers no search results': 'لا توجد نتائج لعملية البحث',
        'workers no workers added': 'لم يتم إضافة عاملين بعد',
        'workers error message': 'حصل خطأ الرجاء المحاولة لاحقا',
        'workers remove worker': 'إزالة العامل',
        'workers add one new worker': 'إضافة عامل جديد واحد',
        'workers add multiple new workers': 'إضافة مجموعة عاملين',
        'workers added multiple new members successfully': 'تم إضافة مجموعة عاملين بنجاح',
        'workers delete workers': 'حذف العاملين',
        'workers confirm deleting workers': 'هل أنت متأكد من حذف {count} من العاملين؟',

        // Subscription
        'subscriptions page': 'الاشتراكات',

        // Settings
        'settings page': 'الإعدادات',
        'settings account settings': 'إعدادات الحساب',
        'settings manage your data': 'يمكنك إدارة جميع بياناتك الخاصة من هنا',
        'settings choose from list': 'اختر من القائمة :',
        'settings profile': 'الملف الشخصي',
        'settings security': 'الأمان',
        'settings notifications': 'الإشعارات',
        'settings coupon': 'القسيمة',
        'settings save changes': 'حفظ التغييرات',
        // Settings Profile personal info
        'settings profile personal info page': 'المعلومات الشخصية',
        'settings profile personal info desc': 'بإمكانك تحديث البيانات الشخصية من هنا',
        // Settings profile image
        'settings profile image page': 'الصورة الشخصية',
        'settings profile image desc': 'بإمكانك تحديث الصورة الشخصية من هنا',
        // Settings profile email
        'settings profile email page': 'البريد الالكتروني',
        'settings profile email desc': 'بإمكانك تغيير البريد الإلكتروني الخاص بك من هنا',
        'settings verified': 'موثق',
        'settings not verified': 'غير موثق',
        'settings verify': 'تحقق',
        'settings send verification code': 'إرسال رقم التحقق',
        'settings current password': 'كلمة المرور الحالية',
        // Settings Security password
        'settings security password page': 'كلمة المرور',
        'settings security password desc': 'بإمكانك تغيير كلمة المرور الخاصة بك من هنا',
        'settings security password current password': 'كلمة المرور الحالية',
        'settings security password new password': 'كلمة المرور الجديدة',
        'settings security password confirm new password': 'إعادة كلمة المرور الجديدة',
        'settings security password successfully updated': 'تم التحديث بنجاح',
        // Settings Security Trusted Devices
        'settings security trusted devices title': 'الأجهزة الموثوقة',
        'settings security trusted devices description': 'قم بتوثيق هذا الجهاز حتى تتمكن من تسجيل الدخول إليه بسهولة وأمان دون الحاجة إلى التحقق بخطوتين.',
        'settings security trusted devices trust this device': 'الوثوق بهذا الجهاز',
        'settings security trusted devices current device': 'الجهاز الحالي',
        'settings security trusted devices remove device': 'إزالة الجهاز',
        'settings security trusted devices trust this device confirmation': 'لن يتم التحقق بخطوتين على هذا الجهاز، هل انت متأكد؟',
        'settings security trusted devices remove device confirmation': 'هل أنت متأكد من إزالة الجهاز',
        'settings security trusted devices trust this device success': 'تم إضافة الجهاز بنجاح',
        'settings security trusted devices remove device success': 'تم إزالة الجهاز بنجاح',
        // Settings Security Two-Factor Authentication
        'settings security two factor title': 'التحقق بخطوتين',
        'settings security two factor description': 'تغيير رقم الهاتف الذي سيتم إرسال رقم التحقق إليه عند تسجيل الدخول',
        'settings security two factor phone number': 'رقم الهاتف',
        'settings security two factor enter verification code sent to': 'ادخل رقم التحقق المرسل الى',
        'settings security two factor send verification code via sms': 'إرسال رقم التحقق عن طريق الرسائل النصية',
        'settings security two factor send verification code via whatsapp': 'إرسال رقم التحقق عن طريق الواتساب',
        'settings security two factor phone number updated successfully': 'لقد تم تحديث رقم الهاتف بنجاح.',
        // Settings Notifications
        'settings notifications title': 'الإشعارات',
        'settings notifications description': 'تفعيل او تعطيل الإشعارات.',
        'settings notifications no customizable notifications': 'لا توجد إشعارات قابلة للتخصيص بعد',
        'settings notifications other': 'اخرى',
        'settings notifications save': 'حفظ',
        'settings notifications successfully updated': 'تم التحديث بنجاح',
        // Settings Coupons
        'settings coupons title': 'الكوبونات',
        'settings coupons coupon code': 'رمز الكوبون',
        'settings coupons apply': 'تطبيق',
        'settings coupons success': 'تم استخدام الكوبون بنجاح',
        // Settings Team
        'settings team title': 'الفريق',
        'settings team description': 'خيارات متعلقة بالفريق وأعضاؤه',
        'settings team show member notes': 'عرض ملاحظات الأعضاء',
        'settings team show member notes tooltip': 'عرض ملاحظات العضو للأعضاء الاخرين أثناء تصفح الملفات',
        'settings team enable instant discussions': 'تفعيل ميزة المناقشات الفورية',
        'settings team enable instant discussions tooltip': 'في حال تم تفعيل ميزة المناقشات الفورية سوف يتم مشاركة المناقشات مع جميع اعضاء الفريق',
        'settings team save': 'حفظ',
        'settings team successfully updated': 'تم التحديث بنجاح',
        // Settings Contracts cover
        'settings contracts cover title': 'غلاف العقد/النموذج',
        'settings contracts cover description': 'يعد غلاف العقد/النموذج الصفحة الأولى منه',
        'settings contracts cover remove': 'إزالة',
        'settings contracts cover success': 'تم التحديث بنجاح',
        'settings contracts cover confirm remove': 'إزالة الغلاف؟',
        // Settings Contracts bg
        'settings contracts bg header title': 'الترويسة الرسمية لأوراق الشركة',
        'settings contracts bg header description': 'سيتم تطبيق الترويسة الرسمية على جميع صفحات العقد/النموذج',
        'settings contracts bg remove': 'إزالة',
        'settings contracts bg success': 'تم التحديث بنجاح',
        'settings contracts bg confirm remove': 'إزالة الترويسة الرسمية؟',

        // Notifications
        'notifications page': 'الإشعارات',

        // Teams
        'team page': 'الفريق',
        'team members page': 'إدارة أعضاء الفريق',
        'team members add new member': 'إضافة مستخدم جديد',
        'team members remove member': 'حذف المستخدم',
        'team members': 'أعضاء الفريق',
        'team members count usage': '{users_count} من {total_seats}',
        'team members search': 'ابحث هنا',
        'team members search no results': 'لا توجد نتائج لعملية البحث',
        'team members not yet added': 'لم يتم إضافة مستخدمين بعد',
        'team members invitation status accepted': 'مقبولة',
        'team members invitation status pending': 'بالانتظار',
        'team members add one new member': 'إضافة مستخدم جديد واحد',
        'team members add multiple new members': 'إضافة مجموعة مستخدمين',
        'team members added multiple new members successfully': 'تم إضافة مجموعة المستخدمين بنجاح',
        'team members send invitation': 'إرسال',
        'team members resend invitation': 'إعادة ارسال بريد التفعيل',
        'team members resend invitation successfully': 'تم إعادة إرسال بريد الكتروني بالتفعيل بنجاح',
        'team members copy invitation link': 'نسخ رابط الدعوة',
        'team members copied invitation link': 'تم نسخ رابط الدعوة',
        'team members removed member': 'تم حذف المستخدم',
        'team members successfully': 'بنجاح',
        'team members not reviewed yet': 'لم يتم مراجعتهم بعد',
        'team members i agree to import data without reviewing': 'أوافق على استيراد البيانات التي لم يتم مراجعتهم',
        'team members added new member successfully and sent activation link': 'تم إنشاء المستخدم بنجاح وإرسال بريد الكتروني بالتفعيل',
        'team members remove member confirmation': 'هل أنت متأكد انك تريد حذف المستخدم',

        // inputs
        'input file upload drag file or': 'قم بوضع الملف أو',
        'input file upload choose file': 'اختر ملف',
        'input file upload click here': 'انقر هنا',
        'input file upload to remove files': 'لإزالة الملفات',

        // upload entities
        'upload entities not reviewed yet': 'لم يتم مراجعتهم بعد',
        'upload entities you have': 'لديك',
        'upload entities i agree to import data without reviewing': 'أوافق على استيراد البيانات التي لم يتم مراجعتهم',
        'upload entities add multiple new members steps': 'خطوة {current} من {total}',
        'upload entities add multiple new members step type': 'النوع',
        'upload entities add multiple new members step upload': 'الرفع',
        'upload entities upload file': 'رفع الملف',
        'upload entities add multiple new members step review data': 'مراجعة البيانات',
        'upload entities add multiple new members how to get started': 'كيف تريد أن تبدأ؟',
        'upload entities uploading file': 'جارٍ رفع الملف',
        'upload entities add multiple new members upload your own file': 'رفع الملف الخاص بك',
        'upload entities add multiple new members download qanoniah template then upload the file': 'حمل قالب قانونية ثم رفع الملف',
        'upload entities download the template to insure a proper upload': 'تنزيل النموذج أولاً للتأكد من تحميل الملف بشكل صحيح',
        'upload entities already downloaded csv template': 'تم تنزيل ملف قالب CSV',
        'upload entities data in file': 'البيانات بالملف',
        'upload entities data reviewed': 'تمت المراجعة',
        'upload entities dont import': 'لا اريد الاستيراد',
        'upload entities column name in file': 'اسم العمود بالملف',
        'upload entities column data in file': 'البيانات بالملف',
        'upload entities qanoniah format': 'صيغة قانونية',
        'upload entities download csv template': 'تنزيل ملف قالب CSV',

        // general
        'general error': 'لقد حصل خطأ ما',
        'general error please try again': 'حصل خطأ الرجاء المحاولة مرة اخرى',
        'this content is not available for your package': 'هذا المحتوى غير متاح للباقة الخاصة بك',
        'successfully updated': 'تم التحديث بنجاح',
        'in': 'في',
        'confirm': 'تأكيد',
        'copy': 'نسخ',
        'close': 'اغلاق',
        'cancel': 'إلغاء',
        'from': 'من',
        'next': 'التالي',
        'back': 'السابق',
        'success': 'نجاح',
        'delete': 'حذف',
        'edit': 'تعديل',
        'first name': 'الاسم الأول',
        'last name': 'الاسم الأخير',
        'user name': 'الاسم',
        'user full name': 'الاسم الكامل',
        'user phone': 'رقم الجوال',
        'user email': 'البريد الالكتروني',
        'interested': 'مهتم',

        'وصف منتج الموارد البشرية': 'هي باقة مصممة خصيصًا لمساعدة إدارات الموارد البشرية في الشركات السعودية على تجنب الغرامات وتخفيض التكاليف القانونية والالتزام بالأنظمة السعودية ، حيث تمكنك قانونية HR من الوصول والفهم والتعامل مع أكبر قاعدة بيانات قانونية سعودية مختصة بأنظمة العمل السعودي، بالإضافة إلى شروحات تفصيلية لمختلف الإجراءات النظامية خطوة بخطوة، وبرنامج عقود ونماذج يحتوي على أكثر من ١٠٠ عقد ونموذج مدقق قانونيًا ومتوافق مع الأنظمة السعودية.',

        // Privacy Policy
        'privacy policy introduction part 1': 'توضّح هذه السياسة البيانات الشخصية التي سيتم جمعها، والغرض من معالجتها وكيفية استخدامها\n' +
            '                            والمسوغ النظامي لجمعها ومعالجتها، والجهات التي سيتم الإفصاح لها عن هذه البيانات - إن وجدت -،\n' +
            '                            والنطاق الجغرافي للمعالجة، وفترة الاحتفاظ بالبيانات وكيفية إتلافها، وحقوق صاحب البيانات\n' +
            '                            الشخصية وكيفية ممارسته لحقوقه وآلية التواصل مع الجهة، وتوضح كذلك التزام الجهات بإتاحة بيانات\n' +
            '                            الأفراد لهم بطرق واضحة وسهلة الوصول وذلك عند جمعها، كربطها على مواقع الويب أو التطبيقات\n' +
            '                            الخاصة بها وذلك وفقًا للضوابط المحددة في نظام حماية البيانات الشخصية ولوائحه التنفيذية\n' +
            '                            الصادرة بالمرسوم الملكي رقم (م/19) وتاريخ 1443/02/09هـ (ويشار إليها فيما بعد في هذه\n' +
            '                            الاتفاقية بـ"النظام").'
    }
}
