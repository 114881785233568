
export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: () => null,
            validator: val => ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', null].includes(val)
        }
    },
    computed: {
        src () {
            if (!this.name) {
                return null
            }
            return require(`~/assets/icons/${this.name}.svg?inline`)
        }
    }
}
