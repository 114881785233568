
import Logo from '~/components/common/Logo.vue'
import Icon from '~/components/UntitledUI/Icon.vue'

export default {
    components: {
        Logo,
        Icon
    }
}
