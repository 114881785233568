
import Icon from '@/components/UntitledUI/Icon.vue'
export default {
    name: 'LanguageSwitcher',
    components: { Icon },
    data () {
        return {
            showNewLabel: false,
            mounted: false
        }
    },
    mounted () {
        this.checkIfVisited()
        this.mounted = true
    },
    methods: {
        checkIfVisited () {
            const visited = localStorage.getItem('englishNewLabel')
            this.showNewLabel = !visited
        },
        markAsVisited () {
            const visited = localStorage.getItem('englishNewLabel')
            if (visited) {
                return
            }
            localStorage.setItem('englishNewLabel', true)
            this.checkIfVisited()
        }
    }
}
